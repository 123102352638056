/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

const scrollTo = (id) => () => {
  const el = document.querySelector(id)
  if (el) return window.scrollTo({top: el.offsetTop - 80, left: 0, behavior: 'smooth'});
  return false
}

export const onRouteUpdate = ({ location: { hash } }) => {
  if (hash) {
    window.setTimeout(scrollTo(hash), 5)
  }
}

//This is a message from the PWA caching busting
export const onServiceWorkerUpdateReady = () => {
    window.location.reload()
}
