module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Brightwaters Christian College","short_name":"Brightwaters CC","start_url":"/","background_color":"#002B5C","theme_color":"#00afb2","display":"minimal-ui","icon":"src/content/images/Brightwaters-Favicon.png","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-rudderstack/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"2E3O0FyTxmBSN2FIi7aD55dATQM","devKey":"27RjVWKnypmZz4EHo1EwhIj7xJt","trackPage":true,"trackPageDelay":50,"dataPlaneUrl":"https://accmarketswvr.dataplane.rudderstack.com","delayLoad":false,"delayLoadTime":1000,"manualLoad":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
